<template>
  <div class="my-warp app_content">
    <div class="warp_body">
      <div class="userInfo">
        <div class="userInfoImg">
          <van-image
              round
              width="70"
              height="70"
              @click="queryPersonInfo"
              :src="userInfo.headUrl?userInfo.headUrl:require('../icons/common/head_photo.png')"
          />
          <!--          <van-icon class="mgt-icon" name="arrow" v-show="userInfo.uid ? true:false" @click="queryPersonInfo"/>-->
          <!--          <span class="mgt-icon1" v-show="userInfo.uid ? true:false" @click="queryPersonInfo">个人信息&nbsp;></span>-->
        </div>
        <div class="userInfoContent">
          <div class="userInfoContentName">
            <div class="vipName" @click="editMyInfo">
              {{ userInfo.name ? userInfo.name : `注册` }}
            </div>
            <div >
              <van-icon name="arrow" @click="queryPersonInfo" />
            </div>
          </div>
          <div class="vipLevel">
            <span style="font-size: 14px;font-weight: 700">{{ userInfo.vip }}</span>
            <span style="font-size: 10px">{{ userInfo.expireTime }}</span>
          </div>
        </div>

      </div>
      <div class="orderDiv">
        <order-type></order-type>
      </div>

      <!--      <div style="margin-top: 5px; background: white;">-->
      <!--&lt;!&ndash;        <div style="display:flex;justify-content: space-between ; padding: 10px; font-size: 12px">&ndash;&gt;-->
      <!--&lt;!&ndash;          <div>我的订单</div>&ndash;&gt;-->
      <!--&lt;!&ndash;          <div style="">查看全部&ndash;&gt;-->
      <!--&lt;!&ndash;            <van-icon/>&ndash;&gt;-->
      <!--&lt;!&ndash;          </div>&ndash;&gt;-->
      <!--&lt;!&ndash;        </div>&ndash;&gt;-->
      <!--        <div>-->
      <!--          <van-cell title="我的订单" value="查看全部" is-link/>-->
      <!--        </div>-->
      <!--        <div style="display:flex;justify-content: space-around ;">-->
      <!--          <div class="child">-->
      <!--            <van-icon name="send-gift-o" badge="9" size="40px"/>-->
      <!--            <div class="orderTypeItem">待确认</div>-->
      <!--          </div>-->
      <!--          <div class="child">-->
      <!--            <van-icon name="send-gift-o" size="40px"/>-->
      <!--            <div  class="orderTypeItem">待完成</div>-->
      <!--          </div>-->
      <!--          <div class="child">-->
      <!--            <van-icon name="send-gift-o" size="40px"/>-->
      <!--            <div  class="orderTypeItem">待评价</div>-->
      <!--          </div>-->
      <!--          <div class="child">-->
      <!--            <van-icon name="send-gift-o" size="40px"/>-->
      <!--            <div  class="orderTypeItem">已完成</div>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--        &lt;!&ndash;        <van-grid square>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <van-badge :content="5" color="#1989fa"></van-badge>&ndash;&gt;-->

      <!--        &lt;!&ndash;          <van-grid-item v-bind:class="{active:currentIndex === index}"&ndash;&gt;-->
      <!--        &lt;!&ndash;                         v-for="(item,index) in orderTab"&ndash;&gt;-->
      <!--        &lt;!&ndash;                         :icon="item.icon"&ndash;&gt;-->
      <!--        &lt;!&ndash;                         :text="item.text"&ndash;&gt;-->
      <!--        &lt;!&ndash;                         :key="index" @click="getOrderList(index,item)" class="grid-item-box">&ndash;&gt;-->

      <!--        &lt;!&ndash;          </van-grid-item>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </van-grid>&ndash;&gt;-->
      <!--      </div>-->
      <div class="menuList">
        <div class="myMenu">

          <van-cell-group v-for="item in myMemu" v-bind:key="item">
            <van-cell :title="item.name" :value="item.value" :is-link="item.linkFlag" @click="clickCell(item)"/>
          </van-cell-group>
        </div>

        <div class="commonMenu">

          <van-cell-group v-for="item in commonMenu" v-bind:key="item">
            <van-cell :title="item.name" :value="item.value" :is-link="item.linkFlag" @click="clickCell(item)"/>
          </van-cell-group>
        </div>
      </div>
    </div>

    <div class="footer_body">
      <footerEle></footerEle>
    </div>
  </div>
</template>

<script>
import footerEle from '@/components/common/footerEle.vue'
import {onMounted, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import OrderType from "./common/orderType";
import API from "../api/User";
import req from "../request/request";
import router from "@/router";

export default {
  name: 'My',
  components: {
    OrderType,
    footerEle
  },
  setup() {
    onMounted(() => {
      methodsMap.getMymenu()
      let user = localStorage.getItem("userinfo")
      data.userInfo = user ? JSON.parse(user) : {}
    });
    const data = reactive({
      myMemu: [],
      commonMenu: [],
      userInfo: {},
      orderList: [{
        img: 'https://img.yzcdn.cn/vant/cat.jpeg',
        name: `张三`,
        id: `00001`,
        phone: `13100000000`,
        time: `2021-01-22`,
      }, {
        img: 'https://img.yzcdn.cn/vant/cat.jpeg',
        name: `张三11`,
        id: `00001`,
        phone: `13100000000`,
        time: `2021-01-22`,
      }],
      currentIndex: 0,
      currentOrderSelect: {text: '确认完成', value: 1, show: true},
      orderTab: [{
        status: 1,
        icon: "send-gift-o",
        text: "待确认"
      }, {
        status: 2,
        icon: "send-gift-o",
        text: "待完成"
      }, {
        status: 3,
        icon: "todo-list-o",
        text: "待评价"
      }, {
        status: 4,
        icon: "point-gift-o",
        text: "已完成"
      }]
    })
    let methodsMap = {
      // 设置
      queryPersonInfo: () => {
        router.push({path: 'PersonalInfo'})
      }, // 设置
      setting: () => {
        router.push({path: 'setting'})
      },
      clickCell: (item) => {
        if (item.type == 'number') {
          window.location.href = "tel:" + item.value
        }
        if (item.type == 'link') {
          router.push(item.route)
        }
      },
      getMymenu: () => {
        req.request(API.myMenu, null, {method: 'GET'}).then(
            (res) => {
              res.data.data.forEach((r) => {
                if (r.showType == data.userInfo.userType) {
                  data.myMemu.push(r);
                } else if (r.showType == 'A') {
                  data.commonMenu.push(r);
                }
              })

            }
        )
      }, editMyInfo: () => {
        if (data.userInfo.uid) {
          // router.push("/VipPay")
        } else {
          router.push("/RegisterSelect")
        }
      }
    }
    return {
      ...methodsMap,
      ...toRefs(data)
    }
  }
}
</script>
<style lang="less" scoped>
.app_content {

  .warp_body {
    padding: 3px
  }

  .active {
    color: #489aee;
    --van-grid-item-text-color: #489aee;
  }

  .child {

    padding: 10px;
    text-align: center;

    .van-icon {

    }

    .orderTypeItem {
      background: white;
      font-size: 13px;
      color: var(--van-grid-item-text-color);
    }
  }

  .orderDiv {
    position: absolute;
    top: 110px;
    width: 92%;
    left: 4%;
  }

  .userInfo {
    background-image: url("../img/myinfobak.png");
    border-radius: 6px;
    position: relative;
    height: 183px;
    color: #FFFFFF;

    &Img {
      position: absolute;
      top: 20px;
      left: 10px;
      z-index: 1;
    }

    .userInfoContent {
      position: absolute;
      top: 40px;
      left: 20px;
      width: 100%;

      &Name {
        display: flex;
        justify-content: space-around;
        align-items: center;

      }

      .vipName {
        font-size: 18px;
      }

      .vipLevel {
        margin-top: 6px;
        color: #dac656;

        > span {
          margin-right: 10px;
        }
      }
    }


    .mgt-icon {
      position: absolute;
      right: 20px;
      top: 34px
    }

    .mgt-icon1 {
      position: absolute;
      right: 20px;
      top: 34px;
      font-size: 12px;
      color: gray;
    }
  }

  .menuList {
    //margin-top: 55px;
    position: relative;
    top: 45px;
  }

  .myMenu {
    margin-top: 9px;
    margin-bottom: -3px;
  }

  .commonMenu {
    margin-top: 9px;
  }
}
</style>