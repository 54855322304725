<template>
  <div class="orderParent">
    <div v-for="item in orderTypeListHead" v-bind:key="item">
      <van-cell :title="item.name" :value="item.value" :is-link="item.linkFlag"
                :to="item.route+'?active='+item.orderStatus"/>
    </div>
    <div class="orderType">
      <div v-for="item in orderTypeListFoot" v-bind:key="item">
        <div class="child" @click="go(item)">
          <van-icon :name="item.icon" :badge="item.count==0?'':item.count" size="40px"/>
          <div class="orderTypeItem">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {onBeforeMount, onMounted, reactive, toRefs} from 'vue'
import API_ORDER from "@/api/Order";
import req from "@/request/request";
import router from "../../router";


export default {
  name: 'orderType',
  components: {},

  setup() {
    onMounted(() => {
      methodsMap.getOrderNUms()
    });
    const data = reactive({
          orderTypeListHead: [],
          orderTypeListFoot: [],
        },
    )

    const num = async function (orderType) {
      await req.request(API_ORDER.orderNums, {orderType: orderType}, {method: 'GET'}).then((res) => {
        let map = res.data.data;
        return map.num
      })
    }

    const methodsMap = {

      getOrderTypes: () => {
        data.orderTypeListFoot.forEach(
            (l) => {
              console.log('123', l)
              req.request(API_ORDER.orderNums, {orderType: l.orderStatus}, {method: 'GET'}).then((res) => {
                l.count = res.data.data.num
                // let count = {count: res.data.data.num}
                // console.log("count", count)
                // let o = Object.assign(l, count)
                // data.orderTypeListFoot.push(o)
              })
            }
        )

      },
      getOrderNUms: () => {
        req.request(API_ORDER.orderNums, null, {method: 'GET'}).then((res) => {
          let list = res.data.data;
          list.forEach(
              (l) => {
                if (l.code == 'all') {
                  data.orderTypeListHead.push(l)
                } else {
                  if (l.orderStatus < 0) l.count = 0 // 使已完成的不进行显示数量
                  if (l.orderStatus == 1) l.count = 0 // 使已完成的不进行显示数量
                  data.orderTypeListFoot.push(l)
                }
              }
          )
          console.log("data.orderTypeListFoot", data.orderTypeListFoot)
        })
      }, go: (item) => {
        router.push({
          path: item.route, query: {
            active: item.orderStatus
          }
        })
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
      ...num,
    };
  }
}
</script>
<style lang="less">

.orderParent {
  background: #FFFFFF;

  .orderType {

    display: flex;
    justify-content: space-around;

    .child {

      padding: 10px;
      text-align: center;

      .van-icon {

      }

      .orderTypeItem {
        font-size: 12px;
        color: var(--van-grid-item-text-color);
      }
    }
  }
}
</style>
